import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import ContentWrapper from "../../ContentWrapper";
import Heading from "../../Heading";
import Image, { ImageType } from "../../ImageComponent";
import Introduction from "../../Introduction";
import PageLink from "../../PageLink";
import ParallexBox from "../../ParallaxBox";

const IMAGE_HEIGHT = 300;

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  ${mq("2")} {
    flex-direction: row;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  background-color: ${theme.color("dark.lightest")};
  margin-top: 190px;
  margin-bottom: 0;
  > * {
    margin-bottom: ${theme.space("m")};
  }
`;

const ImageWrapper = styled.div`
  ${mq("2")} {
    flex: 1 1 50%;
    padding: ${theme.space("xxxl")} 50px ${theme.space("xxxl")} 0;
    position: relative;
    > * {
      margin-top: 0;
    }
  }
`;

const ImageContentWrapper = styled(ParallexBox)`
  display: flex;
  overflow: hidden;
  height: ${IMAGE_HEIGHT}px;
  width: 100%;

  ${mq("2")} {
    position: absolute;
    height: 75%;
    width: 80%;
    left: -${theme.space("s")};
    top: -${theme.space("xl")};
  }
  ${mq("3")} {
    top: unset;
    width: 80%;
    height: calc(100%);
    bottom: ${theme.space("xxxl")};
  }
`;

const TextWrapper = styled.div`
  padding-top: ${theme.space("xl")};
  padding-bottom: ${theme.space("xxxl")};

  ${mq("2")} {
    padding-top: ${IMAGE_HEIGHT}px;
    flex: 1 1 50%;
    padding: ${theme.space("xxxl")} 50px ${theme.space("xxxl")} 0;
    > * {
      margin-top: 0;
    }
  }
`;

const TextContentWrapper = styled.div`
  ${mq("2")} {
    max-width: 524px;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  img {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;
const StyledIntroduction = styled(Introduction)`
  margin-bottom: ${theme.spacing("s")};
`;

const StyledHeading = styled(Heading)`
  position: relative;
  z-index: 1;
  margin-top: 0;
  margin-bottom: ${theme.spacing("s")};
`;

function ContentBlockV3({ image, title, description, url, urlText, ...props }) {
  return (
    <Wrapper {...props}>
      <StyledContentWrapper columns={12}>
        <ImageWrapper>
          <ImageContentWrapper scrollOffset={["10%", "-10%"]}>
            <StyledImage {...image} />
          </ImageContentWrapper>
        </ImageWrapper>
        <TextWrapper>
          <TextContentWrapper>
            <StyledHeading size={2}>{title}</StyledHeading>
            <StyledIntroduction html>{description}</StyledIntroduction>
            <PageLink url={url} title={urlText} />
          </TextContentWrapper>
        </TextWrapper>
      </StyledContentWrapper>
    </Wrapper>
  );
}

ContentBlockV3.propTypes = {
  image: PropTypes.shape(ImageType),
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  urlText: PropTypes.string,
};

export default ContentBlockV3;
